export default class Header {
  constructor(el) {
    this.DOM = { el: el };
    this.DOM.body = document.body;
    this.DOM.brand = this.DOM.el.querySelector(".brand");
    this.DOM.toggle = this.DOM.el.querySelector('.Toggle__menu');
    // this.DOM.menuContainer = this.DOM.el.querySelector('Nav__container');
    this.DOM.hiddenNavigation = this.DOM.el.querySelector(".Nav__hidden");
    this.menuOpen = false;

    this.init();
  }

  init() {
    window.DEVMODE && console.log("init Header");

    this.events();
  }

  events() {
    this.DOM.toggle.addEventListener("click", e => {
      e.preventDefault();
      this.menuOpen === true ? this.close() : this.open();
    })
  }

  open() {
    return new Promise((resolve, reject) => {
      if (window.DEVMODE) console.log("Open Menu");
      this.menuOpen = true;
      this.DOM.body.classList.add("showMenu");
      this.DOM.body.style.overflow = "hidden";
      this.DOM.toggle.classList.add("is--active");
      this.DOM.hiddenNavigation.classList.add("is--active");
    });
  }

  close() {
    return new Promise((resolve, reject) => {
      if (window.DEVMODE) console.log("Close Menu");
      this.menuOpen = false;
      this.DOM.body.classList.remove("showMenu");
      this.DOM.body.style.overflow = "";
      this.DOM.toggle.classList.remove("is--active");
      this.DOM.hiddenNavigation.classList.remove("is--active");
    });
  }
}
