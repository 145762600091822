/* global tarteaucitron gtag */

// Highway
import Highway from '@dogstudio/highway';
import DefaultPageRenderer from '../pages/page-renderer.js';
import DefaultPageTransition from '../pages/page-transition.js';
import PageHome from '../pages/page-home.js';
import PagePartenaires from '../pages/page-partenaires.js';
import SingleProjection from '../pages/single-projection.js';
import SingleDocumentaire from '../pages/page-single-documentaire.js';
import PageContact from '../pages/page-contact.js';
import PageAgenda from "../pages/page-agenda.js";
import PageAddEvent from "../pages/page-add-event.js";
import PageDocumentaires from "../pages/page-documentaires";
import PageActualites from "../pages/page-actualites";
import PageSingleActualites from "../pages/page-single-actualites";
import PageAbout from "../pages/page-about";

import pagePrllx from './prllx.js';
import pageInView from './inview.js';
import pageScroll from './scroll.js';
import pageAnchors from './anchors.js';

export default class Routes {

  constructor(Header, Search) {

    this.view = {
      anchors: null,
      prllx: null,
      scroll: null,
      inview: null
    };

    this.header = Header;
    this.search = Search;
    this.navLinks = this.header.DOM.el.querySelectorAll('.Nav__link');
    this.navLinks = this.header.DOM.el.querySelectorAll('.Nav__link');

    this.createHighway();
    this.initView(document.body);

  }

  createHighway() {

    window.H = new Highway.Core({
      renderers: {
        pagename: DefaultPageRenderer,
        pageHome: PageHome,
        pagePartenaires: PagePartenaires,
        singleProjection: SingleProjection,
        singleDocumentaire: SingleDocumentaire,
        pageContact: PageContact,
        pageAgenda: PageAgenda,
        pageAddEvent: PageAddEvent,
        pageDocumentaires: PageDocumentaires,
        pageActualites: PageActualites,
        pageSingleActualites: PageSingleActualites,
        pageAbout: PageAbout,
      },
      transitions: {
        default: DefaultPageTransition
      }
    });

    window.H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {

      if (window.DEVMODE) {
        console.log('NAVIGATE_OUT');
      }

      // Close Search
      if (this.search.searchOpen) this.search.close();

      // RemoveEffects
      if (this.view.anchors) this.view.anchors = null;
      if (this.view.inview) this.view.inview = null;

      if (this.view.prllx) {
        this.view.prllx.destroy();
        this.view.prllx = null;
      }

      if (this.view.scroll) {
        this.view.scroll.destroy();
        this.view.scroll = null;
      }

    });

    window.H.on('NAVIGATE_IN', ({ to, trigger, location }) => {

      if (window.DEVMODE) {
        console.log('NAVIGATE_IN');
      }

      // Menu active links
      this.navLinks.forEach((link) => {
        link.classList.remove('is--active');
        if (link.href === location.href) {
          link.classList.add('is--active');
        }
      });

      // Close search
      if (this.search.searchOpen) this.search.close(false);

      this.initView(to.view);

    });

    window.H.on('NAVIGATE_END', ({ to, from, trigger, location }) => {

      if (window.DEVMODE) {
        console.log('NAVIGATE_END');
      }

      // Analytics

      if ((typeof tarteaucitron !== 'undefined') && (tarteaucitron.state.gtag)) {
        gtag('config', tarteaucitron.user.gtagUa, {
          'page_path': location.pathname,
          'page_title': to.page.title,
          'page_location': location.href
        });
      }

    });

  }

  initView(container) {

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    // Menu Toggle
    if (this.header.menuOpen) {
      const click = new Event('click');
      this.header.DOM.toggle.dispatchEvent(click);
    }

    if (container.querySelectorAll('[data-anchor]').length > 0) this.view.anchors = new pageAnchors(container);

    // Animations
    if (container.querySelector('[data-scroll-container]')) this.view.scroll = new pageScroll(container);
    if (container.querySelectorAll('[data-prllxfrom]').length > 0) this.view.prllx = new pagePrllx(container);
    if (container.querySelectorAll('[data-inview]').length > 0) this.view.inview = new pageInView(container);

  }
}
