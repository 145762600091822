/* global gsap, Swiper, Plyr */
import Highway from '@dogstudio/highway';

export default class PageHome extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('PageHome');
    this.DOM = { view: this.wrap.lastElementChild };

    this.initAnchor();

    this.header = document.querySelector(".Header");
    this.header.dataset.theme = "blue";

    this.linkDocumentary = document.querySelector(".Header .is--documentary");
    this.linkDocumentary && this.linkDocumentary.classList.add("is--home");
  }

  initAnchor() {

    this.DOM.AnchorsLinks = this.DOM.view.querySelectorAll('[data-anchor]');
    this.DOM.AnchorsLinks.forEach((item) => {

      item.addEventListener('click', (e) => {
        e.preventDefault();
        const targetAnchor = e.currentTarget.dataset.target;
        gsap.to(window, {duration: 1.8, scrollTo: { y: targetAnchor, autoKill: false }, ease: 'expo.inOut' });
      });
    });
  }

  onLeaveCompleted() {
    this.linkDocumentary && this.linkDocumentary.classList.remove("is--home");
  }
}
