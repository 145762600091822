/* global gsap, Power3, Swiper, Plyr */
import Highway from '@dogstudio/highway';

export default class SingleProjection extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('SingleProjection');

    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.diaporama = this.DOM.view.querySelector('.Diaporama');
    this.DOM.video = this.DOM.view.querySelector('.Projection__video');

    if (this.DOM.diaporama) this.initDiaporama();
    if (this.DOM.video) this.initPlyr();

    this.header = document.querySelector(".Header");
    this.header.dataset.theme = "blue";
  }

  initDiaporama() {
    this.Diaporama = new Swiper(this.DOM.diaporama, {
      virtualTranslate: true,
      navigation: {
        nextEl: document.querySelector('.next'),
        prevEl: document.querySelector('.prev')
      }
    });

  }

  initPlyr() {

    this.plyr = new Plyr(this.DOM.video.querySelector('.plyr__video'));
    this.plyrPlay = this.DOM.video.querySelector('.plyr__icon');

    // Event Play
    this.plyrPlay?.addEventListener('click', (e) => {
      this.plyr.play();
      gsap.to(window, {duration: 0.6, scrollTo: { y: this.DOM.video, autoKill: false }, power3: 'expo.inOut' });
    });

  }

  onLeaveComplete() {
    if (this.Diaporama) this.Diaporama.destroy();
    if (this.plyr) this.plyr.destroy();
  }
}
